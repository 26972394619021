@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.formContainer {
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0 14px 28px $color-shadow, 0 10px 10px $color-shadow;
    position: relative;
    overflow: hidden;
    width: 384px;
    max-width: 100%;
    min-height: 480px;

    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 50px;
        height: 100%;
        text-align: center;
    }

    .logo {
        width: 160px;
        height: 60px;
        overflow: "visible";
        margin: 20px 0;
    }

    .link {
        font-size: 14px;
        margin: 15px 0;

        &:hover {
            text-decoration: underline;
        }
    }

    input {
        background-color: $color-brand;
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 100%;
    }

    .buttom {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: bold;
        padding: 12px 45px;
    }

    p {
        color: $coler-text-red;
        margin: 15px 0;
    }
}
