@import 'src/styles/variables/layout';

.container {
    display: flex;
    flex-direction: row;

    > * + * {
        margin-left: spacing(2);
    }

    &.hasSpaceBetween {
        justify-content: space-between;
        align-items: center;

        > * + * {
            margin-left: auto;
        }
    }

    &.hasSmallSpacing {
        > * + * {
            margin-left: spacing(1);
        }
    }

    &.hasLargeSpacing {
        > * + * {
            margin-left: spacing(3);
        }
    }

    &.contentStretched {
        > * {
            flex: 1;
        }
    }
}
