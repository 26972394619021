.container {
    @import 'src/styles/variables/layout';
    @import 'src/styles/variables/colors';

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: $color-brand;
    position: fixed;
}
