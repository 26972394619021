@import "src/styles/variables/colors";
@import "src/styles/variables/layout";

.loader {
    color: $color-red;
    margin: auto;
}

.slide {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
}
