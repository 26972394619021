.container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;

    svg {
        color: inherit;
        font-size: inherit;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transform-origin: 50% 50%;

        * {
            fill: currentColor;
        }
    }
}
