@import 'src/styles/variables/fonts';
@import 'src/styles/font-faces';

* {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: inherit;
    word-break: break-word;
}

*:before,
*:after {
    box-sizing: inherit;
}

a[href],
area[href],
button,
details,
input,
iframe,
select,
textarea,
[contentEditable=''],
[contentEditable='true'],
[contentEditable='TRUE'],
[tabindex]:not([tabindex^='-']),
:not([disabled]) {
    @import 'src/styles/variables/colors';

    &:focus {
        @include outline-styling;
    }
}

button {
    font-size: inherit;
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol,
ul {
    padding: 0;
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

html,
body {
    min-height: 100vh;
    padding: 0;
}

body {
    --vh: 1vh;

    font-family: $font-default;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

.has-overlay {
    height: 100%;
    overflow: hidden;
}
