$visual-grid-base-size: 1em;
$space-base: 8px;

@function spacing($multiplier: 1, $correction: 0px) {
    @return $space-base * $multiplier + $correction;
}

@function visual-grid-size($multiplier: 1, $correction: 0em) {
    @return $visual-grid-base-size * $multiplier + $correction;
}

@function px-to-em($pixelsize) {
    @return $pixelsize/16 * 1em;
}

$mobile-action-bar-height: spacing(8);
$tablet-action-bar-height: spacing(11);
$desktop-action-bar-height: spacing(9);

$screen-smallest: 768px;
$screen-desktop: 1024px;
$screen-ultra-wide: 2048px;

$visual-height-breakpoint-extra-large: 1510px;
$visual-height-breakpoint-large: 1200px;
$visual-height-breakpoint-medium: 900px;
$visual-height-breakpoint-mobile: 667px;
$visual-height-breakpoint-mobile-xsmall: 568px;

@mixin media-query($breakpoint) {
    $ultra-wide-breakpoint: '(min-width: #{$screen-ultra-wide})';
    $desktop-breakpoint: '(max-width: #{$screen-ultra-wide - 1px})';
    $tablet-breakpoint: '(max-width: #{$screen-desktop - 1px})';
    $mobile-breakpoint: '(max-width: #{$screen-smallest - 1px})';

    @if ($breakpoint == ultra-wide) {
        @media #{$ultra-wide-breakpoint} {
            @content;
        }
    }

    @if ($breakpoint == tablet) {
        @media #{$tablet-breakpoint} {
            @content;
        }
    } @else if ($breakpoint == mobile) {
        @media #{$mobile-breakpoint} {
            @content;
        }
    } @else if ($breakpoint == desktop) {
        @media #{$desktop-breakpoint} {
            @content;
        }
    } @else {
        @media (max-width: #{$breakpoint}) {
            @content;
        }
    }
}

@mixin visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}

// IMPORTANT: When updating these values, please also update Carousel.tsx mediaQueryModifier()
@mixin visual-font-size-media-queries {
    @include media-query(ultra-wide) {
        font-size: 8px;

        @media screen and (max-height: $visual-height-breakpoint-extra-large) {
            font-size: 6px;
        }

        @media screen and (max-height: $visual-height-breakpoint-large) {
            font-size: 4px;
        }

        @media screen and (max-height: $visual-height-breakpoint-medium) {
            font-size: 2px;
        }
    }

    @include media-query(desktop) {
        font-size: 6px;

        @media screen and (max-height: $visual-height-breakpoint-large) {
            font-size: 4px;
        }

        @media screen and (max-height: $visual-height-breakpoint-medium) {
            font-size: 2px;
        }
    }

    @include media-query(tablet) {
        font-size: 4px;
    }

    @include media-query(mobile) {
        font-size: 2px;

        @media screen and (max-height: $visual-height-breakpoint-mobile) {
            font-size: 1.5px;
        }

        @media screen and (max-height: $visual-height-breakpoint-mobile-xsmall) {
            font-size: 1px;
        }
    }
}

@mixin screen-max-widths {
    @include media-query(ultra-wide) {
        max-width: calc(100% - calc(#{spacing(13, 4)} * 2));
    }

    @include media-query(desktop) {
        max-width: calc(100% - calc(#{spacing(13, 4)} * 2));
    }

    @include media-query(tablet) {
        max-width: calc(100% - calc(#{spacing(5)} * 2));
    }

    @include media-query(mobile) {
        max-width: calc(100% - calc(#{spacing(2)} * 2));
    }
}
