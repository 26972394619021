@import "src/styles/variables/colors";
@import "src/styles/variables/layout";

.loader {
    color: $color-red;
    margin: auto;
}

.text {
    font-size: 14px;
    padding: 20px;
}

.link {
    color: $coler-text-red;
    font-size: 12px;

    &:hover {
        text-decoration: underline;
    }
}