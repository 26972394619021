$color-brand: #f9f9f9;
$color-secondary: #ffa939;
$color-brand-light-gray: #e6e6e6;
$color-subtle-gray: #f2f2f2;
$color-brand-gray: #c2c2c2;
$color-brand-black: #5c5c5c;
$color-danger: #d60000;
$color-darker-gray: #939393;
$color-blue: #354bdb;
$color-white: #FFFFFF;
$color-red: #FF0000;

$color-button-primary:  #FF0000;

$color-text-orange: #fbb26b;
$color-text-white: #ffffff;
$color-text-black: #141b41;
$color-text-blue: #354bdb;
$coler-text-red: #FF0000;
$color-text-darker-gray: #939393;

$color-shadow: rgba(106, 106, 106, 0.5);

@mixin outline-styling {
    outline-style: solid;
    outline-color: $color-brand;
    outline-width: 1px;
    outline-offset: -1px;
}
