@import "src/styles/variables/colors";
@import "src/styles/variables/layout";

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 spacing(3);
    align-items: center;
}

.slidesContainer {
    display: flex;
    position: relative;
    width: 800px;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $color-white;
}

.slide {
    display: none;
    align-content: center;
    background-color: $color-white;
    padding: 0 25px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.isActive {
        display: flex;
    }
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    width: auto;
    top: 50%;
    padding: spacing(1);
    color: $color-red;
    font-weight: bold;
    font-size: 10px;
    transition: 0.6s ease;
    border-radius: 0 2px 2px 0;
    user-select: none;
    border: none;
    background-color: transparent;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

.prev:hover,
.next:hover {
    background-color: $color-darker-gray;
}

.dot {
    cursor: pointer;
    height: 6px;
    width: 6px;
    margin: spacing(1);
    background-color: $color-darker-gray;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    &.isActive,
    &:hover {
        background-color: $color-red;
    }
}

.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

.dotsCotainer {
    text-align: center;
}

@-webkit-keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}
