@import 'src/styles/variables/colors';
@import 'src/styles/variables/layout';

.header {
    display: block;
    position: fixed;
    width: 100%;
    background: $color-white;
    height: 65px;
    margin-bottom: 20px;
    box-shadow: 0 2px 9px 0 $color-shadow;
    z-index:1000;
    top: 0px;
}

.logoLink {
    position: fixed;
    top: 0.5rem;
    left: 1.6rem;
}

.authContainer {
    position: fixed;
    top: 0.8rem;
    right: 1.6rem;
}

.logoutContainer {
    letter-spacing: 1px;
    font-size: 12px;
    color: $coler-text-red;

    &:hover {
        text-decoration: underline;
    }
}

.user {
    letter-spacing: 1px;
    font-size: 14px;
}

.tenantContainer {
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: bold;

    margin-right: 5px;

    cursor: default;

    &:hover + .dropdownContent {
        display: block;
    }
}

.dropdownContent {
    letter-spacing: 1px;
    font-size: 12px;
    color: $color-white;
    display: none;
    position: absolute;
    background-color: $color-white;
    z-index: 1;

    max-height: calc(var(--app-height) - 65px);

    overflow-y: scroll;

    right: -5px;
    top: 2.6rem;

    width: 340px;

    border: 2px solid $color-brand;
    border-radius: 10px;

    margin-bottom: 10px;

    &:hover {
        display: block;
    }

    span {
        display: block;
        text-decoration: none;
        color: $color-text-black;
        padding: 12px 16px;
        &:hover {
            background-color: $color-brand;
            cursor: pointer;
        }

        &.isSelected {
            color: $coler-text-red;
            cursor: default;
        }
    }
}
