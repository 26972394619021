@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-Medium.woff2') format('woff2'),
        url('../assets/fonts/Spartan-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-Regular.woff2') format('woff2'),
        url('../assets/fonts/Spartan-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-Thin.woff2') format('woff2'),
        url('../assets/fonts/Spartan-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/Spartan-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/Spartan-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Spartan-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-Bold.woff2') format('woff2'),
        url('../assets/fonts/Spartan-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-Black.woff2') format('woff2'),
        url('../assets/fonts/Spartan-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Spartan';
    src: url('../assets/fonts/Spartan-Light.woff2') format('woff2'),
        url('../assets/fonts/Spartan-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
