@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: spacing(10);
    width: 100%;
    height: 100%;
}