@import "src/styles/variables/colors";
@import "src/styles/variables/layout";

.loader {
    color: $color-red;
    margin: auto;
}

.input {
    background-color: $color-brand;
    border: none;
    padding: spacing(2) spacing(3);
    margin: spacing(2) 0;
    width: 100%;

    &.isInvalid {
        border: 1px solid $color-red;
    }
}

.inputRow {
    width: 100%;
    position: relative;
}

.tenantName {
    width: 100%;
    position: relative;
    font-weight: bold;;
}

.validadionLoader {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    color: $color-red;
}