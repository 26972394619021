@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';

.container {
    // Variables
    $default-color: #fff;
    $default-background-color: $color-button-primary;
    $default-border-color: transparent;

    $secondary-color: $color-text-black;
    $secondary-background-color: #ffffff;
    $secondary-border-color: $color-button-primary;
    $secondary-pressed-color: #efceda;

    $danger-color: #fff;
    $danger-background-color: $color-danger;
    $danger-border-color: transparent;

    $cancel-color: $color-darker-gray;
    $cancel-background-color: #fff;
    $cancel-border-color: $color-brand-gray;

    $tab-color: $color-darker-gray;
    $tab-background-color: transparent;
    $tab-border-color: $color-brand-gray;

    $tab-color-active: $color-brand;
    $tab-background-color-active: transparent;
    $tab-border-color-active: $color-brand;

    $ghost-color: $color-secondary;
    $ghost-background-color: transparent;
    $ghost-border-color: $color-secondary;

    $ghost-color-active: $color-brand;
    $ghost-background-color-active: transparent;
    $ghost-border-color-active: $color-brand;

    position: relative;
    display: inline-flex;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    touch-action: manipulation;
    cursor: pointer;
    &.container.isDisabled {
        cursor: not-allowed;
    }
    background-image: none;
    white-space: nowrap;
    padding: spacing(1, 4) spacing(3) spacing(1) spacing(3);
    font-size: 12px;
    user-select: none;
    transition: background-color 225ms cubic-bezier(0.645, 0.045, 0.355, 1);
    color: $default-color;
    border: 1px solid $default-border-color;
    box-shadow: none;
    background-color: $default-background-color;
    text-decoration: none;
    outline: none;
    border-radius: 20px;

    .loaderContainer {
        display: flex;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        background-color: $default-background-color;
    }

    .inner {
        display: flex;
        align-items: center;
    }

    &.hasIcon {
        .icon {
            font-size: 12px;
            margin-left: 8px;
            color: inherit;
        }
    }

    // MODIFIERS
    &:hover,
    &:focus {
        outline: none;
        background-color: darken($default-background-color, 5%);
        z-index: 1;
    }

    &:active {
        background-color: darken($default-background-color, 10%);
        border-color: $default-border-color;
        box-shadow: none;
        z-index: 1;
    }

    &.isPrimary {
        background-color: $default-background-color;
        border-color: $default-border-color;
        color: $default-color;

        &:hover,
        &:focus {
            background-color: darken($default-background-color, 5%);
        }

        &:active {
            background-color: darken($default-background-color, 10%);
            border-color: $default-border-color;
        }

        &.container.isDisabled {
            color: fade_out($default-color, 0.5);
            background-color: fade_out($default-background-color, 0.5);
            border-color: $default-border-color;
        }

        .loaderContainer {
            background-color: $default-background-color;
        }
    }

    &.isSecondary {
        background-color: $secondary-background-color;
        color: $secondary-color;
        border-color: $secondary-border-color;

        &:hover,
        &:focus {
            background-color: darken($secondary-background-color, 5%);
        }

        &:active {
            background-color: darken($secondary-background-color, 10%);
        }

        &.container.isDisabled {
            color: fade_out($secondary-color, 0.5);
            background-color: fade_out($secondary-background-color, 0.5);
        }

        .loaderContainer {
            background-color: $secondary-background-color;
        }
    }

    &.isDanger {
        background-color: $danger-background-color;
        border-color: $danger-border-color;
        color: $danger-color;

        &:hover,
        &:focus {
            background-color: darken($danger-background-color, 5%);
        }

        &:active {
            background-color: darken($danger-background-color, 10%);
            border-color: $danger-border-color;
        }

        &.container.isDisabled {
            color: fade_out($danger-color, 0.5);
            background-color: fade_out($danger-background-color, 0.5);
            border-color: $danger-border-color;
        }
    }

    &.isCancel {
        background-color: $cancel-background-color;
        border-color: $cancel-border-color;
        color: $cancel-color;

        &:hover,
        &:focus {
            background-color: darken($cancel-background-color, 5%);
        }

        &:active {
            background-color: darken($cancel-background-color, 10%);
            border-color: $cancel-border-color;
        }

        &.container.isDisabled {
            color: fade_out($cancel-color, 0.5);
            background-color: fade_out($cancel-background-color, 0.5);
            border-color: $cancel-border-color;
        }
    }

    &.isTab {
        background-color: $tab-background-color;
        border-color: $tab-border-color;
        color: $tab-color;
        transition: color 225ms cubic-bezier(0.645, 0.045, 0.355, 1),
            border-color 225ms cubic-bezier(0.645, 0.045, 0.355, 1);
        padding: spacing(0.5, 2) spacing(1);

        &:hover,
        &:focus {
            color: darken($tab-color-active, 5%);
            border-color: darken($tab-border-color-active, 5%);
        }

        &:active {
            color: darken($tab-color-active, 10%);
            border-color: darken($tab-border-color-active, 10%);
        }

        &.container.isDisabled {
            color: fade_out($tab-color, 0.5);
            background-color: $tab-background-color;
            border-color: fade_out($tab-border-color, 0.5);
        }

        &.container.isHighlighted {
            color: darken($tab-color-active, 10%);
            border-color: darken($tab-border-color-active, 10%);
        }
    }

    &.isGhost {
        background-color: $ghost-background-color;
        border-color: $ghost-border-color;
        color: $ghost-color;
        transition: color 225ms cubic-bezier(0.645, 0.045, 0.355, 1),
            border-color 225ms cubic-bezier(0.645, 0.045, 0.355, 1);
        padding-top: spacing(0.5, 2);
        padding-bottom: spacing(0.5, 2);

        &:hover,
        &:focus {
            color: darken($ghost-color, 5%);
            border-color: darken($ghost-border-color, 5%);
        }

        &:active {
            color: darken($ghost-color, 10%);
            border-color: darken($ghost-border-color, 10%);
        }

        &.container.isDisabled {
            color: fade_out($ghost-color, 0.5);
            background-color: $ghost-background-color;
            border-color: fade_out($ghost-border-color, 0.5);
        }

        &.container.isHighlighted {
            color: darken($ghost-color-active, 10%);
            border-color: darken($ghost-border-color-active, 10%);
        }
    }

    // Modifiers
    &.container.isNotStyled {
        background-color: transparent;
        background: none;
        box-shadow: none;
        border: none;
        padding: 0;
        transition: none;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-align: inherit;
        white-space: pre-wrap;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border: none;
            background: none;
        }

        .inner {
            width: 100%;
        }
    }

    &.container.isWide {
        width: 100%;

        .inner {
            width: 100%;
            justify-content: space-between;
        }
    }

    &.container.isIconButton {
        padding: spacing(1, 4);

        .icon {
            margin-left: 0;
        }
    }
}
