@import "src/styles/variables/colors";

.container {
    width: 100%;
    margin: 10px 0;
    align-items: center;

    &.isSelected {
        background-color: $color-brand;
    }
}

.headerContainer {
    width: 100%;
    margin: 25px 0;
    align-items: center;
    font-weight: bold;
}

.code {
    width: 60%;
}

.ref {
    width: 100%;
}

.eui {
    width: 40%;
}

