@import "src/styles/variables/colors";

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}

.context {
    margin: auto;
}

.link {
    color: $coler-text-red;
    font-size: 14px;
}

.text {
    font-size: 14px;
    padding: 20px;
}
