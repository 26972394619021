@import "src/styles/variables/colors";
@import "src/styles/variables/layout";


.close {
    position: absolute;
    top: spacing(1);
    right: spacing(1);
    z-index: 1;
    color: $color-red;
} 

.image {
    max-width: 100%;

    @include media-query(mobile) {
        max-height: 420px;
    }
}

.photoButton {
    background-color: transparent;
    border: none;
    margin: auto;
}

.row {
    width: 100%;
}